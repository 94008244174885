import { singleFileUpload } from '../lib/direct_upload';

export default class extends ApplicationController {
  static targets = ['input'];

  initialize() {
    this.inputTargets.forEach(input => {
      singleFileUpload(input)
    });
  }
}
