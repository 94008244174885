import { DataTable } from "simple-datatables"

export default class extends ApplicationController {
  static datatable;

  connect() {
    this.datatable = new DataTable(this.element, {
      paging: false,
      labels: {
        placeholder: "Vyhledat\u2026",
        noRows: "Nebyli nalezeni žádní psi."
      }
    })
  }

  disconnect() {
    this.datatable = null
  }
}
