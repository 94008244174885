export default class extends ApplicationController {
  static values = { delay: Number, clicked: Boolean }

  connect() {
    this.timeout = setTimeout(this.clickOnce, this.delayValue)
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  // Private

  clickOnce = () => {
    if (this.clickedValue) return
    this.clickedValue = true
    this.element.click()
  }
}
