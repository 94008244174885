export default class extends ApplicationController {
  static targets = ['checkbox']

  connect() {
    this.validateCheckboxes()
  }

  update(event) {
    if (event?.target.checked && this.isSelectionInvalid) {
      const filteredCheckboxTargets = this.filteredCheckboxTargets(event.target)
      filteredCheckboxTargets.forEach(checkbox => checkbox.checked = false)
    }
  }

  // Private

  filteredCheckboxTargets(selected) {
    if (selected == null) return
    return this.selectedCheckboxTargets.filter(checkbox => checkbox.id !== selected.id)
  }

  validateCheckboxes() {
    if (this.hasCheckboxTarget && this.isSelectionInvalid) {
      const [selected, ...rest] = this.selectedCheckboxTargets
      rest.forEach(checkbox => checkbox.checked = false)
    }
  }

  get selectedCheckboxTargets() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }

  get isSelectionInvalid() {
    return this.selectedCheckboxTargets.length && this.selectedCheckboxTargets.length > 1
  }
}
