import CableReady from 'cable_ready'
import { subscribeTo } from '../helpers'

export default class extends ApplicationController {
  static targets = [ 'output' ]

  connect() {
    this.channelName = 'InbreedingMatchesChannel'
    this.subscribeToChannel()
  }

  disconnect() {
    this.unsubscribeFromChannel()
  }

  async subscribeToChannel() {
    this.subscription = await subscribeTo(this.channelName, {
      connected: this.streamChannelConnected.bind(this),
      received: this.streamDataReceived.bind(this),
      disconnected: this.streamChannelDisconnected.bind(this)
    })
  }

  unsubscribeFromChannel() {
    this.subscription?.unsubscribe()
  }

  streamChannelConnected() {
    console.log(`🚀%c[${this.channelName}] Client is connected…`, "color: green; font-weight:bold;")
  }

  streamDataReceived(data) {
    if (data.cableReady) CableReady.perform(data.operations)
  }

  streamChannelDisconnected() {
    console.log(`🏁%c[${this.channelName}] Client is disconnected…`, "color: red; font-weight:bold;")
  }
}
