import { refreshWithScrollLock } from "../lib/utils";

export default class extends ApplicationController {
  static targets = ['form'];

  connect() {
    if (this.hasFormTarget) {
      this.formTarget.addEventListener('ajax:success', this.handleSubmit);
    }
  }

  disconnect() {
    if (this.hasFormTarget) {
      this.formTarget.removeEventListener('ajax:success', this.handleSubmit);
    }
  }

  handleSubmit = event => {
    const modalTarget = document.getElementById('app-modal');
    if (modalTarget) modalTarget.close();
    return refreshWithScrollLock()
  };
}
