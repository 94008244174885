export default class extends ApplicationController {
  static values = { loaded: Boolean, url: String }
  connect() {
    if (!this.loadedValue) {
      this.load();
    }
  }

  load() {
    fetch(this.urlValue)
      .then(response => {
        return response.text();
      })
      .then(content => {
        this.element.innerHTML = content;
        this.loadedValue = true;
      });
  }
}
