import { makeElement } from '../lib/utils';
import { isMobileApp, request } from '../helpers'

export default class extends ApplicationController {
  static targets = ['modal'];

  initialize() {
    if (!this.modalContainer) this.initializeContainer();
  }

  connect() {
    this.resetModal();
  }

  openModal(event) {
    event.preventDefault();

    const type = isMobileApp ? 'full' : 'box';
    this.modalTarget.setAttribute('type', type);
    const eventType = event?.type.split(':')

    if (eventType && eventType[0] === 'ajax') {
      this.handleResponse(event, (fragment) => {
        this.render(fragment)
      })
    } else {
      const { currentTarget: { dataset: { url } } } = event;
      this.performFetch(url);
    }

    this.modalTarget.open();
  }

  closeModal(event) {
    event.preventDefault();

    if (this.hasModalTarget) {
      this.modalTarget.close();
      this.resetModal();
    }
  }

  closeWithKeyboard(event) {
    if (event.keyCode === 27) this.closeModal(event);
  }

  resetModal() {
    if (this.hasModalTarget) {
      this.modalTarget.innerHTML = '';
      this.addLoader();
      this.setLoaded(false);
    }
  }

  // Private

  abortPendingFetchRequest() {
    this.abortController?.abort()
  }

  async performFetch(url) {
    this.abortPendingFetchRequest()
    this.abortController = new AbortController
    const { signal } = this.abortController
    try {
      const response = await request.get(url, { contentType: 'application/javascript', responseKind: 'xhr', signal });
      this.render(response)
    } catch (error) {
      if (error.name != 'AbortError') throw error
      const message = `🚨 %c[Error]: ${error}`
      console.log(message, 'color: #ff0000; font-weight:bold;')
    }
  }

  render(html) {
    this.removeLoader();
    this.modalTarget.innerHTML = ''
    this.modalTarget.innerHTML = html;
    this.setLoaded(true);
  }

  addLoader() {
    this.modalTarget.classList.add('loading');
  }

  removeLoader() {
    this.modalTarget.classList.remove('loading');
  }

  setLoaded(value) {
    this.modalTarget.setAttribute('loaded', value);
  }

  initializeContainer() {
    let element = makeElement('app-modal', {});
    element.classList.add('loadable');
    document.body.appendChild(element);
  }

  get modalContainer() {
    return document.getElementById('app-modal');
  }

  handleResponse(event, callback) {
    const xhr = event.detail[2]
    callback(xhr.response)
  }
}
