function safeParse(str) {
  try {
    return JSON.parse(str)
  } catch (_err) {
    return str
  }
}

function toggleInputFields(container, flag) {
  const inputs = container.querySelectorAll('input, textarea')

  inputs.forEach(input => {
    flag ? input.setAttribute('disabled', true) : input.removeAttribute('disabled')
  })
}

export default class extends ApplicationController {
  static classes = ['hidden']
  static targets = ['shelf'];
  static values = { trigger: String, disableInputFields: Boolean }

  initialize() {
    this.shelfTargets.forEach(shelf => {
      const trigger = document.querySelector(`[data-trigger-id~=${this.triggerValue}]`);
      const triggerOn = safeParse(shelf.dataset.triggerOn)
      const isMulti = Array.isArray(triggerOn)

      let flag = null

      switch (trigger.type) {
        case 'radio':
          const checked = document.querySelector(`[data-trigger-id~=${this.triggerValue}]:checked`);
          flag = (checked.checked && !(isMulti ? triggerOn.includes(checked.value) : checked.value === String(triggerOn)))

          if (this.disableInputFieldsValue) toggleInputFields(shelf, flag)
          return shelf.classList.toggle(this.hiddenClass, flag)
          break;
        case 'checkbox':
          flag = !(isMulti ? triggerOn.includes(trigger.checked) : trigger.checked === triggerOn)

          if (this.disableInputFieldsValue) toggleInputFields(shelf, flag)
          return shelf.classList.toggle(this.hiddenClass, flag)
          break;
        case 'select-one':
          flag = !(isMulti ? triggerOn.includes(trigger.value) : trigger.value === triggerOn)

          if (this.disableInputFieldsValue) toggleInputFields(shelf, flag)
          return shelf.classList.toggle(this.hiddenClass, flag)
          break;
        default:
          break;
      }
    });
  }

  select(event) {
    event.preventDefault();

    this.shelfTargets.forEach(shelf => {
      const { target } = event
      const triggerOn = safeParse(shelf.dataset.triggerOn)
      const isMulti = Array.isArray(triggerOn)

      let flag = null

      switch (target.type) {
        case 'radio':
          flag = !(target.checked && (isMulti ? triggerOn.includes(target.value) : target.value === String(triggerOn)))

          if (this.disableInputFieldsValue) toggleInputFields(shelf, flag)
          return shelf.classList.toggle(this.hiddenClass, flag)
          break;
        case 'checkbox':
          flag = !(isMulti ? triggerOn.includes(target.checked) : target.checked === triggerOn)

          if (this.disableInputFieldsValue) toggleInputFields(shelf, flag)
          return shelf.classList.toggle(this.hiddenClass, flag)
          break;
        case 'select-one':
          flag = !(isMulti ? triggerOn.includes(target.value) : target.value === triggerOn)

          if (this.disableInputFieldsValue) toggleInputFields(shelf, flag)
          return shelf.classList.toggle(this.hiddenClass, flag)
          break;
        default:
          break;
      }
    });
  }

  get trigger() {
    return this.data.get('trigger')
  }
}
