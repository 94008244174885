export default class extends ApplicationController {
  connect() {
    this.element.addEventListener('ajax:beforeSend', this.removeExistingErrorMessages.bind(this))
    this.element.addEventListener('ajax:success', this.onSuccess)
    this.element.addEventListener('ajax:error', this.onError)
  }

  disconnect() {
    this.element.removeEventListener('ajax:beforeSend', this.removeExistingErrorMessages.bind(this))
    this.element.removeEventListener('ajax:success', this.onSuccess)
    this.element.removeEventListener('ajax:error', this.onError)
  }

  onSuccess = (event) => {}

  onError = (event) => {
    this.removeExistingErrorMessages()

    if (event.detail[2].status !== 422) return
    const errors = event.detail[0]
    this.showErrorForInvalidFields(errors)
  }

  shouldValidateField(field) {
    return (
      !field.disabled &&
      field.type !== undefined &&
      !['file', 'reset', 'submit', 'button'].includes(field.type)
    )
  }

  removeExistingErrorMessages() {
    this.formFields.forEach((field) => {
      field.classList.remove('error')

      const fieldContainer = field.closest('.field')
      if (!fieldContainer) return
      const existingErrorMessageElement = fieldContainer.querySelector('field__error')
      if (existingErrorMessageElement)
        existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)
    })
  }

  showErrorForInvalidFields(errors) {
    for (let [key, values] of Object.entries(errors)) {
      const field = this.element.querySelector(`[id$='${key}']`)

      if (field && this.shouldValidateField(field)) {
        this.showErrorForInvalidField(field, values)
      }
    }
  }

  showErrorForInvalidField(field, values) {
    const outer = field.closest('.field')
    const inner = field.closest('.field-row--input')
    const label = outer.getElementsByTagName('LABEl')[0]
    const container = inner || outer

    outer.classList.add('--invalid')
    field.classList.add('--invalid')

    if (label) label.classList.add('--invalid')

    values.forEach((message) => {
      container.insertAdjacentHTML('beforeend', this.buildFieldErrorHtml(message))
    })
  }

  buildFieldErrorHtml(message) {
    return `<div class="field__error">
      <div class="field-error__box">
        <svg aria-hidden="true" class="icon icon-color--red" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0113.634 16H2.365A2.365 2.365 0 01.25 12.577L5.884 1.308a2.365 2.365 0 014.231 0zM8 10.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z" fill="currentColor" fill-rule="evenodd" />
        </svg>
        <div class="field-error__message">
          <span>
            ${message}
          </span>
        </div>
      </div>
    </div>`
  }

  get formFields() {
    return Array.from(this.element.elements)
  }

  get firstInvalidField() {
    return this.formFields.find((field) => !field.checkValidity())
  }
}
