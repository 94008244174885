export default class extends ApplicationController {
  static targets = ['input'];
  static values = { dateFormat: String };

  select(event) {
    event.preventDefault();

    if (event.target && event.target.value === 0) return;

    const selected = flatpickr.parseDate(event.target.value, this.dateFormat);

    if (this.inputTarget && this.inputTarget.value.length === 0) {
      this.inputTarget.value = this.getYearForInput(selected);
    }
  }

  getYearForInput(date) {
    if (date.getFullYear() > this.currentYear) {
      return date.getFullYear();
    } else {
      return this.currentYear;
    }
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  get dateFormat() {
    return this.hasDateFormatValue ? this.dateFormatValue : 'd.m.Y';
  }
}
