import { dispatchEvent } from '../lib/utils'

class ModalElement extends HTMLElement {
  constructor() {
    super();

    this.eventHandlers = [];
  }

  connectedCallback() {
    this.setAttribute('id', 'app-modal');
    this.setAttribute('class', 'app-modal');
    this.setAttribute('data-modal-target', 'modal');
    this.setAttribute('data-action', 'close->modal#resetModal');
    this.setAttribute('opened', 'false');
    this.setAttribute('ready', 'false');

    if (
      (this.hasAttribute('type') || this.setAttribute('type', 'box'),
      this.getAttribute('opened') !== 'false')
    )
      return this.open();
  }

  disconnectedCallback() {
    return this.close();
  }

  static get observedAttributes() {
    return ['loaded', 'opened', 'type'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case 'loaded':
        if (newValue === 'true') {
          const focusable = this.querySelector('[autofocus]');
          focusable != null && focusable.focus();
        }
        break;
      default:
        break;
    }
  }

  adoptedCallback() {
    // called when the element is moved to a new document
    // (happens in document.adoptNode, very rarely used)
  }

  open() {
    if (this.getAttribute('opened') !== 'true')
      this.setAttribute('opened', 'true');

    this.eventHandlers.push(
      this.addEventListener('click', () => this.close.bind(this))
    );

    this.eventHandlers.push(
      this.addEventListener('keydown', () => this.close.bind(this))
    );

    dispatchEvent(this, 'open');
  }

  close() {
    if (this.getAttribute('opened') !== 'false') {
      this.setAttribute('opened', 'false');

      let handler;
      while ((handler = this.eventHandlers.pop())) {
        handler.destroy();
      }
      return dispatchEvent(this, 'close');
    }
  }

  closesOnOutsideClick() {
    return !this.matches('[data-behavior~=close_button_only]');
  }
}

customElements.define('app-modal', ModalElement);
