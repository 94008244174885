import { animateElementWithClass, removeElement } from '../lib/utils';

export default class extends ApplicationController {
  static values = { duration: Number }
  static timeout;

  initialize() {
    this.timeout = setTimeout(() => {
      this.animateAndRemove(this.element);
    }, this.duration);
  }

  disconnect() {
    this.timeout = null;
  }

  close() {
    clearTimeout(this.timeout);
    this.animateAndRemove(this.element);
  }

  animateAndRemove(elem) {
    animateElementWithClass(elem, ['animated', 'scaleFadeOut'], () => {
      removeElement(elem);
    });
  }

  get duration() {
    return (this.hasDurationValue ? this.durationValue : 5) * 1000;
  }
}
