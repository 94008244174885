import { refreshWithScrollLock } from '../lib/utils';

export default class extends ApplicationController {
  connect() {
    this.element.addEventListener('ajax:success', this.onSuccess);
  }

  disconnect() {
    this.element.removeEventListener('ajax:success', this.onSuccess);
  }

  onSuccess = event => refreshWithScrollLock();
}
