import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

require.context('icons', true)

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))
import "trix"
import "@rails/actiontext"


if (window.localStorage.getItem("accessible") === "true") {
  document.documentElement.classList.toggle("accessible", true);
}
