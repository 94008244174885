class _Response {
  constructor(response) {
    this.response = response
  }

  get statusCode() {
    return this.response.status
  }

  get ok() {
    return this.response.ok
  }

  get unauthenticated() {
    return this.statusCode === 401
  }

  get authenticationURL() {
    return this.response.headers.get('WWW-Authenticate')
  }

  get contentType() {
    const contentType = this.response.headers.get('Content-Type') || ''
    return contentType.replace(/;.*$/, '')
  }

  get headers() {
    return this.response.headers
  }

  get html() {
    if (this.contentType.match(/^(application|text)\/(html|xhtml\+xml|javascript)$/)) {
      return this.response.text()
    }
    return Promise.reject(`Expected an HTML response but got "${this.contentType}" instead`) // eslint-disable-line prefer-promise-reject-errors
  }

  get json() {
    if (this.contentType.match(/^application\/json/)) {
      return this.response.json()
    }
    return Promise.reject(`Expected a JSON response but got "${this.contentType}" instead`) // eslint-disable-line prefer-promise-reject-errors
  }

  get text() {
    return this.response.text()
  }
}

export { _Response as Response }
