import { toArray } from '../lib/utils';

export default class extends ApplicationController {
  connect() {
    if (this.disabled) return
    this.updateOptionsOnInit()
  }

  select(event) {
    if (this.disabled) return
    if (['A', 'BUTTON'].includes(document.activeElement.tagName)) return

    this.updateOptionsOnSelect();
  }

  updateOptionsOnInit() {
    const slim = this.element.slim
    if (slim) {
      const { data } = slim.data
      const items = data.map(item => {
        if (this.selected.includes(item.value)) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }

        return item
      })

      slim.setData(items)
    }
  }

  updateOptionsOnSelect() {
    const elements = this.container.querySelectorAll('[data-target~=trophy-input]')
    const selected = this.selected

    Array.from(elements).forEach(element => {
      if (element != this.element) {
        Array.from(element.options).forEach(item => {
          if (selected.includes(item.value)) {
            item.disabled = true;
            item.setAttribute('disabled', 'true')
          } else {
            item.disabled = false;
            item.setAttribute('disabled', 'false')
          }
        })
      }
    })
  }

  get selected() {
    const elements = this.container.querySelectorAll('[data-target~=trophy-input]')

    return Array.from(elements).map(element => {
      const select = element ? element.slim : null;

      if (select && element != this.element) {
        return select.selected()
      }

      return
    }).filter(id => {
      if (id === '' || id == null) return null
      return id
    })
  }

  get container() {
    return this.element.closest('[data-target~=nested-select-container]');
  }

  get disabled() {
    return this.data.get('disabled') === 'true';
  }
}
