import { initQuintessentialDnD } from '../lib/drag_n_drop'

export default class extends ApplicationController {
  static values = { itemSelector: String, listSelector: String, param: String, url: String }

  connect() {
    initQuintessentialDnD({
      itemSelector: this.itemSelectorValue,
      listSelector: this.listSelectorValue,
      param: this.paramValue,
      url: this.urlValue
    });
  }
}
