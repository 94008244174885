export default class extends ApplicationController {
  static classes = ['hidden', 'show']
  static targets = ['slide', 'button'];
  static values = { index: Number }

  initialize() {
    this.showCurrentSlide();
    this.toggleButtons();
  }

  next(event) {
    if (this.indexValue !== this.count) this.indexValue++;
    this.toggleButtons();
  }

  previous(event) {
    if (this.indexValue !== 0) this.indexValue--;
    this.toggleButtons();
  }

  showCurrentSlide() {
    this.slideTargets.forEach((el, i) => {
      el.classList.toggle(this.showClass, this.indexValue == i);
    });
  }

  toggleButtons() {
    this.buttonTargets.forEach(el => {
      const type = el.dataset.type;

      if (type === 'previous') {
        el.classList.toggle(this.hiddenClass, this.indexValue === 0);
      } else {
        el.classList.toggle(this.hiddenClass, this.indexValue === this.count);
      }
    });
  }

  indexValueChanged() {
    this.showCurrentSlide()
  }

  get count() {
    return this.slideTargets.length > 0 ? this.slideTargets.length - 1 : 0;
  }
}
