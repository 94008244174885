import { request } from '../helpers';
import qs from 'qs';

export default class extends ApplicationController {
  static targets = ['form', 'breedingUntilInput', 'currentlyBreedingInput', 'dateOfBirthInput', 'sexInput'];
  static values = {
    breedCentresUrl: String,
    examsUrl: String,
    parentsUrl: String,
    trophiesUrl: String,
    femaleYears: Number,
    maleYears: Number,
    sex: String
  }

  fetchAssociations = (event) => {
    const { value } = event.target

    if (this.shouldFetchBreedCentres) this.fetchBreedCentres(value);
    if (this.shouldFetchParents) this.fetchParents(value);
    if (this.shouldFetchExams) this.fetchExams(value);
    if (this.shouldFetchTrophies) this.fetchTrophies(value);
  };

  changeSex = (event) => {
    this.sexValue = event.currentTarget.value
  }

  fillBreedingDate = (event) => {

    const currentlyBreeding = this.currentlyBreedingInputTarget && this.currentlyBreedingInputTarget.checked === true
    const dateOfBirth = this.dateOfBirthInputTarget && this.dateOfBirthInputTarget.value

    console.log("DOG:", this.currentlyBreedingInputTarget)

    if (!dateOfBirth) return;

    const breedingUntil = this.breedingUntilInputTarget
    const datePicker = breedingUntil._flatpickr

    let value

    if (currentlyBreeding) {
      let years = this.sexValue == 'male' ? this.maleYearsValue : this.femaleYearsValue
      const parsed = flatpickr.parseDate(dateOfBirth, 'Y.m.d')
      value = new Date(parsed.setFullYear(parsed.getFullYear() + years));
    } else {
      value = null
    }

    datePicker.setDate(value, true, 'd.m.Y')
  };

  fetchBreedCentres(breed) {
    this.performFetch(this.breedCentresUrlValue, {by_breed: breed}, this.replaceBreedCentresOptions);
  }

  fetchParents(breed) {
    this.performFetch(this.parentsUrlValue, {breed_eq: breed}, this.replaceParentsOptions);
  }

  fetchExams(breed) {
    this.performFetch(this.examsUrlValue, {by_breed: breed}, this.replaceExamsOptions);
  }

  fetchTrophies(breed) {
    this.performFetch(this.trophiesUrlValue, {by_breed: breed}, this.replaceTrophiesOptions);
  }

  async performFetch(url, query, callbackFn) {
    try {
      const fetchUrl = `${url}?${qs.stringify({ q: { ...query } })}`
      const response = await request.get(fetchUrl, { contentType: 'application/json', responseKind: 'json' });

      callbackFn(response)
    } catch (error) {
      const message = `🚨 %c[Error]: ${error}`
      console.log(message, 'color: #ff0000; font-weight:bold;')
    }
  }

  replaceBreedCentresOptions = (result) => {
    const raw = this.element.querySelector(`[data-target~=breed-centre-input]`);
    const select = raw ? raw.slim : null;

    this.replaceOptions(select, result, 'id', 'name');
  };

  replaceParentsOptions = (result) => {
    ['male', 'female'].forEach((sex) => {
      const raw = this.element.querySelector(
        `[data-target~=dog-parent-${sex}-input]`
      );

      const select = raw ? raw.slim : null;
      const data = result.filter((el) => el.sex === sex);

      this.replaceOptions(select, data, 'id', 'formatted_name');
    });
  };

  replaceExamsOptions = (result) => {
    const raw = this.element.querySelector(`[data-target~=exam-input]`);
    const select = raw ? raw.slim : null;

    this.replaceOptions(select, result, 'abbreviation', 'name');
  };

  replaceTrophiesOptions = (result) => {
    const selectors = this.element.querySelectorAll(`[data-target~=trophy-input]`);

    selectors.forEach(selector => {
      const select = selector ? selector.slim : null;

      this.replaceOptions(select, result, 'id', 'name');
    })
  };

  replaceOptions(select, options, value_key, text_key) {
    if (!select) return;
    const data = options.map((el) => {
      return Object.assign(
        {},
        {
          text: el[text_key],
          value: el[value_key],
        }
      );
    });

    if (select) {
      select.setData(data);
      select.set([]);
    }
  }

  get shouldFetchBreedCentres() {
    const selector = this.element.querySelector(
      `[data-target~=breed-centre-input]`
    );
    return Boolean(selector);
  }

  get shouldFetchParents() {
    return ['male', 'female'].some((sex) => {
      const selector = this.element.querySelector(
        `[data-target~=dog-parent-${sex}-input]`
      );

      return Boolean(selector);
    });
  }

  get shouldFetchExams() {
    const selector = this.element.querySelector(
      `[data-target~=exam-input]`
    );
    return Boolean(selector);
  }

  get shouldFetchTrophies() {
    const selector = this.element.querySelector(
      `[data-target~=trophy-input]`
    );
    return Boolean(selector);
  }
}
