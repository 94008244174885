import { Controller } from 'stimulus'

export default class extends Controller {
  toggleAccessibility() {
    document.documentElement.classList.toggle("accessible");
    window.localStorage.setItem(
      "accessible",
      document.documentElement.classList.contains("accessible")
    );
  }
}
