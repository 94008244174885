import { Request } from '../lib/http'

export async function request(method, url, options) {
  const req = new Request(method, url, options)
  const res = await req.perform()
  if (!res.ok) throw new Error(res.statusCode)
  return req.responseKind === 'json' ? res.json : res.text
}

['get', 'post', 'put', 'delete'].forEach((method) => {
  request[method] = (...args) => request(method, ...args)
})

request.getJSON = (url, options = {}) => request.get(url, { responseKind: 'json', ...options })
