import { request } from '../helpers'

export default class extends ApplicationController {
  static targets = ['app', 'subdomain']
  static values = { url: String }

  update(event) {
    const { value } = event.currentTarget
    if (value) {
      const url = `${this.urlValue}/${value}`
      this.performFetch(url)
    } else {
      this.setSubdomainInput(null, false)
    }
  }

  async performFetch(url) {
    this.abortPendingFetchRequest()
    this.abortController = new AbortController()
    const { signal } = this.abortController
    try {
      const response = await request.get(url, {
        contentType: 'application/json',
        responseKind: 'json',
        signal,
      })

      if (response) {
        console.log(response)
        this.setSubdomainInput(response.subdomain, true)
      } else {
        this.setSubdomainInput(null, false)
      }
    } catch (error) {
      if (error.name != 'AbortError') throw error
      const message = `🚨 %c[Error]: ${error}`
      console.log(message, 'color: #ff0000; font-weight:bold;')
      this.setSubdomainInput(null, false)
    }
  }

  abortPendingFetchRequest() {
    this.abortController?.abort()
  }

  setSubdomainInput(value, readonly) {
    this.subdomainTarget.removeAttribute('readonly')
    this.subdomainTarget.value = value

    if (readonly) this.subdomainTarget.setAttribute('readonly', readonly)
  }
}
