import Uppy from "@uppy/core";
import XHRUpload from '@uppy/xhr-upload'
import AwsS3 from '@uppy/aws-s3'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import FileInput from '@uppy/file-input'
import Informer from '@uppy/informer'
import ProgressBar from '@uppy/progress-bar'
import ThumbnailGenerator from '@uppy/thumbnail-generator'

import czechLocale from '@uppy/locales/lib/cs_CZ';
import { insertAfter } from './utils';

export const singleFileUpload = fileInput => {
  const wrapper = fileInput.closest('[data-role~=file-container]');
  const hiddenInput = wrapper.querySelector(`[data-role~=${fileInput.dataset.uploadResultElement}]`);
  const imagePreview = wrapper.querySelector(`[data-role~=${fileInput.dataset.previewElement}]`);
  const preserveContainer = fileInput.dataset?.preserveContainer === "true";
  const container = fileInput.parentNode;
  const chooseI18n = container.dataset.fileI18n;

  container.removeChild(fileInput);

  const uppy = initUppy(fileInput);

  uppy
    .use(FileInput, {
      target: container,
      pretty: true,
      locale: {
        strings: {
          chooseFiles: chooseI18n
        }
      }
    })
    .use(Informer, { target: container })
    .use(ProgressBar, { target: container })
    .use(ThumbnailGenerator, { thumbnailWidth: 600 });

  if (imagePreview) {
    uppy.on('thumbnail:generated', (file, preview) => {
      imagePreview.src = preview;
    });
  }

  uppy.on('upload', (data) => {
    const form = container.closest('form')
    if (!form) return;

    const button = form.querySelector('button[type="submit"]');
    if (button) {
      button.setAttribute('disabled', true)
    }
  })

  uppy.on('upload-success', (file, response) => {
    const fileData = uploadedFileData(file, response, fileInput);
    if (!preserveContainer) container.classList.add('visually-hidden');
    hiddenInput.value = fileData;

    const { status } = response
    if ((status >= 200 && status <= 299) && imagePreview == null) {
      let html = document.createElement('span');
      html.classList.add(
        'flex',
        'font-medium',
        'py-2',
        'text-default'
      );
      html.textContent += file.name;
      insertAfter(html, container);
    }
  });

  uppy.on('complete', (_) => {
    const form = container.closest('form')
    if (!form) return;

    const button = form.querySelector('button[type="submit"]');
    if (button) {
      button.removeAttribute('disabled')
    }
  })
};

const initUppy = fileInput => {
  const allowedFileTypes = fileInput.accept.length
    ? { allowedFileTypes: fileInput.accept.split(',') }
    : {};

  const uppy = new Uppy({
    id: fileInput.id,
    autoProceed: true,
    locale: czechLocale,
    restrictions: {
      ...allowedFileTypes,
      maxNumberOfFiles: 1
    }
  });

  if (fileInput.dataset.uploadServer == 's3') {
    uppy.use(AwsS3, {
      companionUrl: '/'
    });
  } else if (fileInput.dataset.uploadServer == 's3_multipart') {
    uppy.use(AwsS3Multipart, {
      companionUrl: '/'
    });
  } else {
    uppy.use(XHRUpload, {
      endpoint: '/upload'
    });
  }

  return uppy;
};

const uploadedFileData = (file, response, fileInput) => {
  if (fileInput.dataset.uploadServer == 's3') {
    const id = file.meta['key'].match(/^cache\/(.+)/)[1];

    return JSON.stringify(fileData(file, id));
  } else if (fileInput.dataset.uploadServer == 's3_multipart') {
    const id = response.uploadURL.match(/\/cache\/([^\?]+)/)[1];

    return JSON.stringify(fileData(file, id));
  } else {
    return JSON.stringify(response.body);
  }
};

const fileData = (file, id) => ({
  id: id,
  storage: 'cache',
  metadata: {
    size: file.size,
    filename: file.name,
    mime_type: file.type
  }
});
