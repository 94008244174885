import SlimSelect, { debounce } from 'slim-select'
import qs from 'qs'
import removeAccents from 'remove-accents'
import { request } from '../helpers'

const fetchData = (url, callbackFn, search = {}) => {
  rFetch(url, {
    responseKind: 'xhr',
    contentType: 'application/json',
    body: { q: search },
  }).then(callbackFn)
}

export default class extends ApplicationController {
  static select
  static timeout
  static values = {
    fetchOn: String,
    input: String,
    label: String,
    queryDependencies: Object,
    queryFilterName: String,
    selected: String,
    url: String,
  }

  connect() {
    let opts = {
      allowDeselect: true,
      allowDeselectOption: true,
      beforeOpen: this.handleOpen.bind(this),
      closeOnSelect: !this.element.multiple,
      deselectLabel: '<span class="text-xs">✖</span>',
      hideSelectedOption: true,
      onChange: this.maybeAddSelectOptions.bind(this),
      placeholder: this.element.dataset.placeholder || 'Vyberte z možností\u2026',
      searchPlaceholder: 'Hledat\u2026',
      searchText: 'Nic nenalezeno\u2026',
      select: this.element,
    }

    if (this.fetchOnSearch) {
      opts = {
        ...opts,
        ajax: this.handleSearchDebounced.bind(this),
        searchingText: 'Probíhá hledání\u2026',
        searchFilter: ({ text }, search) => true,
      }
    } else {
      opts = {
        ...opts,
        searchFilter: ({ text }, search) =>
          removeAccents(text).toLowerCase().indexOf(removeAccents(search).toLowerCase()) !== -1,
      }
    }

    this.select = new SlimSelect(opts)

    if (this.fetchOnConnect && !this.fetchOnSearch) {
      this.performSearch(this.urlValue, this.populateOptions)
    }
  }

  disconnect() {
    this.select.destroy()
    this.select = null
  }

  handleOpen = (info = null) => {
    this.calculateDropdownPosition()
    this.maybeAddSelectOptions(info)
  }

  handleSearchDebounced = (query, callbackFn) => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.handleSearch(query, callbackFn), 300)
  }

  handleSearch(query, callbackFn) {
    if (!this.fetchOnSearch) return

    if (query.length < 3) {
      const placeholder = this.hasPlaceholderOptionValue
        ? this.placeholderOptionValue
        : 'Zadejte hledaný výraz\u2026'
      callbackFn(placeholder)
      return
    }

    const url = new URL(this.urlValue)
    const { origin, pathname, search } = url

    const parsed = qs.parse(search, { ignoreQueryPrefix: true })
    const { q } = parsed
    const existing = q == null ? parsed : q

    const deps = this.hasQueryDependenciesValue ? this.queryDependenciesValue : {}
    let filters = Object.keys(deps).reduce((memo, key) => {
      const selector = `[data-dependency-id~=${deps[key]}]`
      const raw = document.querySelector(`${selector}`)
      let element
      let value

      switch (raw.type) {
        case 'radio':
        case 'checkbox':
          element = document.querySelector(`${selector}:checked`)
          value = element?.dataset?.value || element?.value
          break
        default:
          element = document.querySelector(`${selector}`)
          value = element?.dataset?.value || element?.value
      }

      if (element && value !== undefined) memo[key] = value
      return memo
    }, {})

    filters = Object.assign(filters, {
      [this.queryFilterNameValue]: query,
    })

    const searchUrl = `${origin}${pathname}?${qs.stringify({ q: { ...filters, ...existing } })}`
    this.performSearch(searchUrl, callbackFn)
  }

  calculateDropdownPosition = () => {
    const select = this.element.slim
    if (select == null) return

    const { container, content } = select.slim
    if (container.closest('th') == null) return

    const clientRect = container.getBoundingClientRect()
    content.style.left = `${clientRect.left}px`
  }

  maybeAddSelectOptions = (info = null) => {
    if (this.element.type === 'select-one') return false
    if (this.element.dataset.preventAddSelectOptions) return false

    const select = this.element.slim
    const current = select.data.data

    const selectedSize = info ? info.length : select.selected().length
    const hasSelectAll = current.filter((v) => v.value === 'Přidat vše\u2026').length > 0
    const hasDeselectAll = current.filter((v) => v.value === 'Odstranit vše\u2026').length > 0

    const applySelectAll = select.selected().includes('Přidat vše\u2026')
    const applyDeselectAll = select.selected().includes('Odstranit vše\u2026')
    const data = current.filter(
      (v) => !['Přidat vše\u2026', 'Odstranit vše\u2026'].includes(v.value)
    )

    if (applySelectAll) {
      select.set(data.map((v) => v.value))
      select.setData(data)
      return
    }

    if (applyDeselectAll) {
      select.set([])
      select.setData(data)
      return
    }

    if (selectedSize && !hasDeselectAll) {
      current.unshift({ value: '', text: 'Odstranit vše\u2026' })
    }

    if (selectedSize !== data.length && !hasSelectAll) {
      current.unshift({ value: '', text: 'Přidat vše\u2026' })
    }

    select.setData(current)
  }

  abortPendingSearchRequest() {
    this.abortController?.abort()
  }

  async performSearch(url, callbackFn, options = {}) {
    this.abortPendingSearchRequest()
    this.abortController = new AbortController()
    const { signal } = this.abortController

    try {
      const response = await request.get(url, {
        contentType: 'application/json',
        responseKind: 'json',
        signal,
        ...options,
      })
      const data = this.fetchOnSearch ? this.transformOptions(response) : response
      return data.length > 0 ? callbackFn(data) : callbackFn('Nic nenalezeno\u2026')
    } catch (error) {
      if (error.name != 'AbortError') throw error
      const message = `🚨 %c[Error]: ${error}`
      console.log(message, 'color: #ff0000; font-weight:bold;')
    }
  }

  populateOptions = (result) => {
    const input = this.select

    if (!input) return
    const data = this.transformOptions(result)

    input.setData(data)
  }

  transformOptions = (data) => {
    return data.map((el) => {
      return Object.assign(
        {},
        {
          text: el[this.labelValue],
          value: el[this.inputValue],
        }
      )
    })
  }

  get fetchOnConnect() {
    return this.hasFetchOnValue && this.fetchOnValue === 'connect'
  }

  get fetchOnSearch() {
    return this.hasFetchOnValue && this.fetchOnValue === 'search'
  }
}
