
export default class extends ApplicationController {
  static targets = ['input']
  static values = {'owns': String}
  static currentQuery
  static filter

  initialize() {
    this.currentQuery = null;
    this.filter = null;
  }

  connect() {
    const input = this.input;
    if (!input) return;
    input.setAttribute('autocomplete', 'off');
    input.setAttribute('spellcheck', 'false');
    input.addEventListener('focus', this.boundFilterResults.bind(this));
    input.addEventListener('change', this.boundFilterResults.bind(this));
    input.addEventListener('input', this.debounceInputChange.bind(this));
  }

  disconnect() {
    const input = this.input;
    if (!input) return;
    input.removeEventListener('focus', this.boundFilterResults.bind(this));
    input.removeEventListener('change', this.boundFilterResults.bind(this));
    input.removeEventListener('input', this.debounceInputChange.bind(this));
  }

  ownsValueChanged() {
    filterResults(this, false)
  }

  boundFilterResults() {
    filterResults(this, false)
  }

  debounceInputChange() {
    debounce(filterResults(this, true))
  }

  reset() {
    const input = this.input;
    if (input) {
      input.value = '';
      input.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }

  get input() {
    if (this.hasInputTarget && this.inputTarget instanceof HTMLInputElement) {
      return this.inputTarget
    } else {
      return null
    }
  }
}

async function filterResults(context, checkCurrentQuery = false) {
  const { element, input } = context
  if (!input) return;
  const query = input.value.trim();
  const id = context.ownsValue;
  if (!id) return;
  const container = document.getElementById(id);
  if (!container) return;
  const list = container.hasAttribute('data-filter-list')
    ? container
    : container.querySelector('[data-filter-list]');
  if (!list) return;
  context.dispatch('filter-input-start', { bubbles: true });
  if (checkCurrentQuery && context.currentQuery === query) return;
  context.currentQuery = query;
  const filter = context.filter || matchSubstring;
  const total = list.childElementCount;
  let count = 0;
  let hideNew = false;
  for (const item of Array.from(list.children)) {
    if (!(item instanceof HTMLElement)) continue;
    const itemText = getText(item);
    const result = filter(item, itemText, query);
    if (result.hideNew === true) hideNew = result.hideNew;
    item.hidden = !result.match;
    if (result.match) count++;
  }
  const newItem = container.querySelector('[data-filter-new-item]');
  const showCreateOption = !!newItem && query.length > 0 && !hideNew;
  if (newItem instanceof HTMLElement) {
    newItem.hidden = !showCreateOption;
    if (showCreateOption) updateNewItem(newItem, query);
  }
  toggleBlankslate(container, count > 0 || showCreateOption);
  context.dispatch('filter-input-updated', {
    bubbles: true,
    detail: {
      count,
      total,
    },
  });
}

function matchSubstring(_item, itemText, query) {
  const match = itemText.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  return {
    match,
    hideNew: itemText === query,
  };
}

function getText(filterableItem) {
  const target =
    filterableItem.querySelector('[data-filter-item-text]') || filterableItem;
  return (target.textContent || '').trim();
}

function updateNewItem(newItem, query) {
  const newItemText = newItem.querySelector('[data-filter-new-item-text]');
  if (newItemText) newItemText.textContent = query;
  const newItemValue = newItem.querySelector('[data-filter-new-item-value]');
  if (
    newItemValue instanceof HTMLInputElement ||
    newItemValue instanceof HTMLButtonElement
  ) {
    newItemValue.value = query;
  }
}

function toggleBlankslate(container, force) {
  const emptyState = container.querySelector('[data-filter-empty-state]');
  if (emptyState instanceof HTMLElement) emptyState.hidden = force;
}

function debounce(callback) {
  let timeout;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      clearTimeout(timeout);
      callback();
    }, 300);
  };
}
