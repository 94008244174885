import { request } from '../helpers'

export default class extends ApplicationController {
  static targets = ['entries', 'pagination']
  static values = {sticky: Boolean}

  initialize() {
    let options = {
      rootMargin: '200px',
    }

    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    this.intersectionObserver.observe(this.observable)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.observable)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  async loadMore() {
    if (this.hasPaginationTarget) {
      let next_page = this.paginationTarget.querySelector("a[rel='next']")
      if (next_page == null) { return }
      let url = next_page.href

      this.performFetch(url)
    }
  }

  abortPendingFetchRequest() {
    this.abortController?.abort()
  }

  async performFetch(url) {
    this.abortPendingFetchRequest()
    this.abortController = new AbortController
    const { signal } = this.abortController
    try {
      const response = await request.get(url, { contentType: 'application/json', responseKind: 'json', signal });

      if (this.stickyValue) this.intersectionObserver.unobserve(this.observable)

      this.entriesTarget.insertAdjacentHTML('beforeend', response.entries)
      this.paginationTarget.innerHTML = response.pagination

      if (this.stickyValue) this.intersectionObserver.observe(this.observable)
    } catch (error) {
      if (error.name != 'AbortError') throw error
      const message = `🚨 %c[Error]: ${error}`
      console.log(message, 'color: #ff0000; font-weight:bold;')
    }
  }

  get shouldShowPlaceholderImage() {
    return !processedImageURLs.has(this.element.src)
  }

  get observable() {
    return this.stickyValue ?
      this.entriesTarget.querySelector('tr:last-of-type') :
      (this.hasPaginationTarget ? this.paginationTarget : null)
  }
}
