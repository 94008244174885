import { Sortable, Plugins } from '@shopify/draggable';
import { refreshWithScrollLock } from './utils';
import { request } from '../helpers'

const Classes = {
  draggable: 'StackedListItem--isDraggable',
  capacity: 'draggable-container-parent--capacity'
};

export const initQuintessentialDnD = ({
  itemSelector,
  listSelector,
  param,
  url,
} = props) => {
  const containers = document.querySelectorAll(
    `[data-target~=${listSelector}]`
  );

  if (containers.length === 0) {
    return false;
  }

  const sortable = new Sortable(containers, {
    draggable: `[data-target~=${itemSelector}]`,
    mirror: {
      constrainDimensions: true
    },
    plugins: [Plugins.ResizeMirror]
  });

  // --- Draggable events --- //
  sortable.on('sortable:sort', evt => {
    const { overContainer, sourceContainer } = evt.dragEvent;

    if (!isListDroppable(overContainer, sourceContainer)) {
      evt.cancel();
    }
  });

  sortable.on('sortable:stop', evt => {
    const { detail, newContainer, oldContainer } = evt;
    const canceled = !isListDroppable(newContainer, oldContainer);

    if (canceled) return false;

    const {
      newIndex,
      dragEvent: { originalSource }
    } = evt;
    const handle = originalSource.dataset.draggableId;

    if (newContainer === oldContainer) {
      // Sort event
      let formData = new FormData();
      formData.set(`${param}[position]`, newIndex);

      perform('put', `${url}/${handle}`, { body: formData })
    } else if (oldContainer.dataset.columnIdx === 'origin') {
      // Create event
      let formData = new FormData();
      formData.set(`${param}[code]`, handle);
      formData.set(`${param}[position]`, newIndex);

      perform('post', url, { body: formData })
    } else if (oldContainer.dataset.columnIdx === 'copy') {
      // Delete event
      perform('delete', `${url}/${handle}`, {})
    }
  });

  return sortable;
};

const isListDroppable = (newContainer, oldContainer) => {
  return (
    newContainer.dataset.itemsSortable === 'true' ||
    oldContainer.dataset.itemsSortable === 'true'
  );
};

async function perform(method, url, options) {
  try {
    await request(method, url, { responseKind: 'html', ...options });
    refreshWithScrollLock()
  } catch (error) {
    throw error
    const message = `🚨 %c[Error]: ${error}`
    console.log(message, 'color: #ff0000; font-weight:bold;')
  }
}
