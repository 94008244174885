function getSortCode(element) {
  return element.getAttribute('data-sort-code') || 0
}

function compare(a, b) {
  return a < b ? 1 : a > b ? -1 : 0 // eslint-disable-line no-nested-ternary
}

function compareElements(left, right) {
  return compare(getSortCode(right), getSortCode(left))
}

function elementsAreSorted([left, ...rights]) {
  for (const right of rights) {
    if (compareElements(left, right) > 0) return false
    left = right // eslint-disable-line no-param-reassign
  }
  return true
}

export default class extends ApplicationController {
  initialize() {
    this.observeMutations(this.sortChildren)
  }

  // Private

  sortChildren() {
    const { children } = this
    if (elementsAreSorted(children)) return
    children.sort(compareElements).forEach(this.append)
  }

  get children() {
    return Array.from(this.element.children)
  }

  append = (child) => this.element.append(child)
}
