import { createPopper } from '@popperjs/core/lib/popper-lite.js';
import { request } from '../helpers'

export default class extends ApplicationController {
  static targets = ['content'];
  static values = {delay: Number, loaded: Boolean, strategy: String, url: String, visible: Boolean}
  static timeout;

  initialize() {
    if (this.strategy === 'center') {
      this.contentTarget.setAttribute(
        'style',
        'transform:translate(-50%, -50%); top:50%; left:50%; position:fixed;'
      );
    } else if (this.strategy === 'custom') {
      this.contentTarget.setAttribute(
        'style',
        `transform:translate(${this.data.get('translateX')}, ${this.data.get(
          'translateY'
        )});`
      );
    } else {
      createPopper(this.element, this.contentTarget, {
        strategy: 'fixed',
      });
    }

    this.contentTarget.innerHTML = ''
  }

  connect() {
    if (this.loadable) this.loadedValue = false
    this.visibleValue = false
  }

  disconnect() {
    if (this.loadable) this.loadedValue = false
    this.visibleValue = false
  }

  mouseOver() {
    if (this.visible) return;

    this.timeout = setTimeout(() => {
      this.show();
      if (this.loadable && !this.loaded) this.performFetch()
      document.addEventListener('mousemove', this.mouseOut.bind(this));
    }, this.delay);
  }

  mouseOut(event) {
    if (this.element.contains(event.target)) return;

    clearTimeout(this.timeout);
    this.hide();
    this.element.removeEventListener('mousemove', this.mouseOut);
  }

  abortPendingFetchRequest() {
    this.abortController?.abort()
  }

  async performFetch() {
    this.abortPendingFetchRequest()
    this.abortController = new AbortController
    const { signal } = this.abortController
    try {
      this.contentTarget.classList.add('loading');
      this.contentTarget.innerHTML = await request.get(this.urlValue, { contentType: 'application/javascript', responseKind: 'xhr', signal });
      this.loadedValue = true;
    } catch (error) {
      if (error.name != "AbortError") throw error
      const message = `🚨 %c[Error]: ${error}`
      console.log(message, 'color: #ff0000; font-weight:bold;')
    } finally {
      this.contentTarget.classList.remove('loading')
    }
  }

  show() {
    this.contentTarget.classList.remove('hidden');
    this.visibleValue = true;
  }

  hide() {
    this.contentTarget.classList.add('hidden');
    this.visibleValue = false;
  }

  get delay() {
    return (this.hasDelayValue ? this.delayValue : 0) * 1000;
  }

  get loadable() {
    return this.hasUrlValue;
  }

  get loaded() {
    return this.hasLoadedValue && this.loadedValue === true;
  }

  get strategy() {
    return this.hasStrategyValue ? this.strategyValue : 'popper';
  }

  get visible() {
    return this.hasVisibleValue && this.visibleValue === true;
  }
}
