import tippy from 'tippy.js'

export default class extends ApplicationController {
  static values = {
    content: String,
    delay: Number,
    placement: String,
    trigger: String,
  }

  connect() {
    tippy(this.element, this.opts)
  }

  disconnect() {
    this.tooltip.unmount()
    this.tooltip.destroy()
  }

  get opts() {
    return {
      content: this.contentValue,
      delay: [this.delayValue, 50],
      placement: this.placementValue,
      trigger: this.triggerValue,
    }
  }

  get tooltip() {
    return this.element._tippy
  }
}
