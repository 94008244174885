import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleable"];

  connect() {
    this.toggleClass = this.data.get("class") || "hidden";
  }

  toggle(event) {
    event.preventDefault();
    this.toggleableTargets.forEach(target => {
      target.classList.toggle(this.toggleClass);
    });
  }

  switch(event) {
    event.preventDefault();
    const target = event.currentTarget;

    switch(target.getAttribute("aria-checked")) {
      case "true":
        target.setAttribute("aria-checked", "false");
        break;
      case "false":
        target.setAttribute("aria-checked", "true");
        break;
    }
  }
}
