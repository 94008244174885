export default class extends ApplicationController {
  clear(evt) {
    const target = evt.target;
    const value = target.value;

    if (value && parseInt(value) === 0) {
      target.value = '';
    }
  }
}
