const smoothSupported = 'scrollBehavior' in document.documentElement.style

let smoothPolyfilled
async function polyfillSmooth() {
  const { polyfill } = await import(
    /* webpackChunkName: "smoothscroll-polyfill" */ 'chunks/smoothscroll-polyfill'
  )
  if (smoothPolyfilled) return
  smoothPolyfilled = true
  polyfill()
}

export async function scrollToElement(
  element,
  { behavior = 'smooth', block = 'start', inline = 'nearest' } = {}
) {
  if (behavior === 'smooth' && !smoothSupported) await polyfillSmooth()
  element.scrollIntoView({ behavior, block, inline })
}
