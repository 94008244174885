window.Rails = require('@rails/ujs')
window.Turbolinks = require('turbolinks')

Turbolinks.start()

require('@fortawesome/fontawesome-free/css/all.min.css')
require('stylesheets/application.pcss')
require('elements')
require('application')

Rails.start()
