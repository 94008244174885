import flatpickr from 'flatpickr'
import {Czech} from 'flatpickr/dist/l10n/cs.js'
import {German} from 'flatpickr/dist/l10n/de.js'
import {english} from 'flatpickr/dist/l10n/default.js'

let language
let date_format

const lng = document.getElementsByTagName("html")[0].getAttribute('lang')
switch (lng) {
  case 'cs':
    date_format = 'd.m.Y'
    language = Czech
    break
  case 'de':
    date_format = 'd.m.Y'
    language = German
    break
  default:
    date_format = 'Y-m-d'
    language = english
}

export default class extends ApplicationController {
  static datepickr
  static targets = ['input']
  static values = { type: String }

  connect() {
    this.datepickr = flatpickr(this.element, this.options)

    if (!this.element.disabled) this.initClearButton(this.element)
  }

  disconnect() {
    this.datepickr.destroy()
    this.datepickr = null
  }

  handleChange(event) {
    const target = event.currentTarget

    if (!target.disabled) this.initClearButton(target)
  }

  initClearButton(element) {
    const value = element.value
    const parent = element.parentElement
    const button = parent.querySelector('[data-target~=datepickr-clear-button]')

    if (value === '') {
      if (button == null) return

      button.removeEventListener('click', this.clearInput)
      button.remove()
    } else {
      if (button == null) {
        element.insertAdjacentHTML('afterend', this.buttonHtml)

        const initializedButton = parent.querySelector('[data-target~=datepickr-clear-button]')
        initializedButton.addEventListener('click', this.clearInput.bind(this))
      }
    }
  }

  clearInput(event) {
    this.datepickr.clear()
  }

  get buttonHtml() {
    return `
      <span
        class='flex align-center justify-end flex-initial cursor-pointer font-bold absolute w-6 h-6 right-2 bottom-1'
        data-target='datepickr-clear-button'
      >
        <span class='text-xs'>✖</span>
      </span>
    `.trim()
  }

  get options() {
    switch (this.typeValue) {
      case 'time':
        return Object.assign(
          {},
          {
            allowInput: true,
            altFormat: 'H:i',
            altInput: true,
            dateFormat: 'H:i',
            enableSeconds: false,
            enableTime: true,
            noCalendar: true,
            time_24hr: true,
            locale: language,
          }
        )
        break
      case 'date':
        return Object.assign(
          {},
          {
            enableTime: false,
            allowInput: true,
            altInput: true,
            altFormat: date_format,
            dateFormat: 'Y.m.d',
            locale: language,
          }
        )
        break
      case 'datetime':
        return Object.assign(
          {},
          {
            enableTime: true,
            altInput: true,
            altFormat: 'd.m.Y H:i',
            dateFormat: 'd.m.Y H:i',
            time_24hr: true,
            locale: language,
          }
        )
        break
      default:
        break
    }
  }
}
